import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//translate
import translate from 'i18n/translate';

import ManagementService from 'shared/management-service';

//Component
import Card from 'components/card';
import Loading from 'components/loading';
import IconData from 'components/icons/data';

import { isEmpty } from 'libs/lodashAlt';

// style
import './index.scss';

const Rates = () => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const tarifas = useSelector((state) => state.administracion.tarifas);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    ManagementService.rates()
      .then((data) => dispatch({ type: 'SET_TARIFAS', tarifas: data.message }))
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch]);

  useEffect(getData, [getData]);

  const { vce, oc } = tarifas;

  if (loading) return <Loading />;

  return (
    <div className="tarifas-content">
      <OcRates tarifas={tarifas} oc={oc} />
      <OcPhoneRates tarifas={tarifas} oc={oc} />
      <VceSmsRates tarifas={tarifas} vce={vce} /> {/* The same as VCE sms rates */}
      <VceRates tarifas={tarifas} vce={vce} />
      <VceVisaRates tarifas={tarifas} vce={vce} />
      <VceSmsRates tarifas={tarifas} vce={vce} />
      <div className="text-content">
        <p>{translate('administration-rate-description')}</p>
      </div>
    </div>
  );
};

export default Rates;

const OcRates = ({ tarifas, oc }) => {
  return (
    <Card icon={<IconData />} title={translate('administration-rate-name', { type: 'OleCams' })}>
      <div className="table-content">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{translate('administration-rate-percent')}</th>
              <th scope="col">€</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(tarifas) && !isEmpty(oc) ? (
              oc.porcentaje.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const OcPhoneRates = ({ tarifas, oc }) => {
  return (
    <Card header={false} table>
      <div className="table-content">
        <table className="table sp">
          <thead>
            <tr>
              <th scope="col">{translate('administration-rate-line-oc')}</th>
              <th scope="col">{translate('administration-rate-euros-minutes')}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(tarifas) && !isEmpty(oc) ? (
              oc.escalado.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const VceRates = ({ tarifas, vce }) => {
  return (
    <Card icon={<IconData />} title={translate('administration-rate-name', { type: 'Videochaterotico' })} table>
      <div className="table-content">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{translate('administration-rate-scaled')}</th>
              <th scope="col">{translate('administration-rate-minut')}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(tarifas) && !isEmpty(vce) ? (
              vce.escalado.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const VceVisaRates = ({ tarifas, vce }) => {
  return (
    <Card header={false} table>
      <div className="table-content">
        <table className="table sp">
          <thead>
            <tr>
              <th scope="col">{translate('administration-rate-packs')}</th>
              <th scope="col">{translate('administration-rate-visa')}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(tarifas) && !isEmpty(vce) ? (
              vce.packs.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

const VceSmsRates = ({ tarifas, vce }) => {
  return (
    <Card header={false} table>
      <div className="table-content">
        <table className="table sp">
          <thead>
            <tr>
              <th scope="col">{translate('administration-rate-oper')}</th>
              <th scope="col">{translate('administration-rate-sms')}</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(tarifas) && !isEmpty(vce) ? (
              vce.operadoras.map((item, index) => {
                return (
                  <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};
