const Trans = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd">
        <path
          transform="translate(7 2)"
          d="M11.58 2H8V0h7v7h-2V3.41L9.17 7.24C9.69 8.03 10 9 10 10c0 2.42-1.72 4.44-4 4.9V17h2v2H6v2H4v-2H2v-2h2v-2.1c-2.28-.46-4-2.48-4-4.9 0-2.761 2.239-5 5-5 1 0 1.96.3 2.75.83L11.58 2zM5 7c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"></path>
      </g>
    </svg>
  );
};

export default Trans;
