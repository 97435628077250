import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';

import UserService from 'shared/user-service';
import { PUBLIC } from 'shared/panel-links';

import Card from 'components/card';
import Button from 'components/button';

import SettingsIcon from 'components/icons/settings';
import DataIcon from 'components/icons/data';

// style
import './index.scss';

const Api = ({ data, setData }) => {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const generate = async (reload = false) => {
    setLoading(true);
    await UserService.generateApiKey().then((responseData) => {
      if (responseData.status !== 'Failed') {
        toast.success(formatMessage({ id: 'global-success-operation' }));
        setData({ ...data, api_key: responseData.message });
      } else toast.error(formatMessage({ id: 'herramientas-error-data' }));
      setLoading(false);
    });
  };

  return (
    <Card icon={<DataIcon />} title={<FormattedMessage id="global-api_text" />}>
      <div className="explanation">
        <FormattedMessage id="global-api_explanation" />
        <br />
        <ul>
          <li>
            <FormattedMessage id="global-api_key_explanation" />
          </li>
          <li>
            <FormattedMessage id="global-api_token_explanation" />
          </li>
        </ul>
      </div>

      {data.api_key && (
        <div className="banner-body">
          <form className="banner-modal">
            <div className="form-group">
              <label>
                <FormattedMessage id="global-affiliate_id" />
              </label>
              <input type="text" className="form-control" name="id" value={data.id} disabled />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="global-api_key" />
              </label>
              <input type="text" className="form-control" name="api_key" value={data.api_key} disabled />
            </div>
          </form>
        </div>
      )}
      <div className="button-content">
        <Button rounded onClick={(e) => generate()} disabled={loading}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin transform="left-4" size="lg" />
          ) : (
            <FormattedMessage id="global-generate_api_key" />
          )}
          {/* {data.api_key ? translate('global-regenerate_api_key') : translate('global-generate_api_key')} */}
        </Button>
      </div>
      <hr />

      <div className="text-center font-weight-bold">
        <FormattedMessage id="global-affiliates-api-docs" />
      </div>

      <div className="button-content documentation">
        <NavLink
          exact={true}
          activeClassName="active"
          to="/docs/api_quick_start.pdf"
          rel="noreferrer noopener"
          target="_blank"
          className="button button--rounded button--min-width">
          <SettingsIcon /> <FormattedMessage id="global-api_quick_start_documentation" />
        </NavLink>
        <NavLink
          activeClassName="active"
          to={PUBLIC.API_DOCS}
          rel="noreferrer noopener"
          target="_blank"
          className="button button--rounded button--min-width">
          <SettingsIcon /> <FormattedMessage id="global-api_documentation" />
        </NavLink>
      </div>

      <hr />

      <div className="text-center font-weight-bold">
        <FormattedMessage id="global-content-api-docs" />
      </div>

      <div className="button-content documentation">
        <NavLink
          exact={true}
          activeClassName="active"
          to="/docs/broker/api_affiliate_brokers_quick_start.pdf"
          rel="noreferrer noopener"
          target="_blank"
          className="button button--rounded button--min-width">
          <SettingsIcon /> <FormattedMessage id="global-api_quick_start_documentation" />
        </NavLink>
        <NavLink
          exact={true}
          activeClassName="active"
          to={PUBLIC.BROKERS_API_DOCS}
          rel="noreferrer noopener"
          target="_blank"
          className="button button--rounded button--min-width">
          <SettingsIcon /> <FormattedMessage id="global-broker-see-api-documentation" />
        </NavLink>
      </div>
    </Card>
  );
};

export default Api;
