const Trans = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M18.58 2H14V0h8v8h-2V3.41l-4.83 4.83c.52.79.83 1.76.83 2.76 0 2.42-1.72 4.44-4 4.9V18h2v2h-2v2h-2v-2H8v-2h2v-2.1c-2.28-.46-4-2.48-4-4.9 0-1 .3-1.96.82-2.74L5.64 7.07l-1.4 1.39-1.41-1.42 1.4-1.39L2 3.42V7H0V0h7v2H3.41l2.23 2.24 1.44-1.43L8.5 4.23 7.06 5.66l1.17 1.18C9 6.31 10 6 11 6s1.96.3 2.75.83L18.58 2zM11 8c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"></path>
      </g>
    </svg>
  );
};

export default Trans;
