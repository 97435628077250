import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAuth } from 'contexts/auth';

//components
import Logo from '../icons/logo';
import Logout from '../icons/logout';
import UserMovil from '../icons/userMovil';
import UserDesktop from '../icons/userDesktop';
import Menu from '../icons/menu';
import Close from '../icons/close';

//style
import './index.scss';

const Header = ({ showLinks, showMenu, showSidebar, showUser, showUserMenu }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { name, admin, logout } = useAuth();

  const backHome = () => history.push('/');

  const logoutUser = () => {
    if (window.confirm(formatMessage({ id: 'global-logout-confirm' }))) {
      logout();
    }
  };

  return (
    <>
      {showLinks && (
        <header className="header-container" id="header">
          {showMenu ? (
            <Menu showSidebar={showSidebar} />
          ) : (
            <span className="close-menu" onClick={(e) => showSidebar()}>
              <Close color="#fff" />
            </span>
          )}
          <Logo backHome={backHome} />
          <UserMovil showUser={showUser} />
          <div id="menu-user" className="bottons-content">
            <span onClick={logoutUser}>
              <Logout color={showUserMenu ? '#fff' : '#A2A9B0'} />
              <FormattedMessage id="sidebar-close" />
            </span>
            <NavLink to="/administracion/datos">
              <span>
                <UserDesktop color={showUserMenu ? '#fff' : '#A2A9B0'} />
                {name} {parseInt(admin) === 1 ? '(Admin)' : ''}
              </span>
            </NavLink>
          </div>
        </header>
      )}
      {!showLinks && (
        <header className="header-login-container">
          <Logo backHome={backHome} />
        </header>
      )}
    </>
  );
};

export default Header;
