import request from './api-service';
const qs = require('qs');

const getProfile = () => {
  return request({
    method: 'get',
    url: '/management/profile',
  });
};

const updateProfile = ({ data, contact_info = [] }) => {
  return request({
    method: 'post',
    url: '/management/updateprofile',
    data: { data, contact_info },
  });
};

const generateApiKey = () => {
  return request({
    method: 'get',
    url: '/management/api_key/generate',
  });
};

const updatePassword = (pwd, old_pwd) =>
  request({
    method: 'post',
    url: '/affiliate/changepassword',
    data: { old_pwd, pwd },
  });

const sendEmailPassword = (email, env) =>
  request({
    method: 'post',
    url: '/requestpass',
    data: { email, env },
  });

const recovery = (new_password, token) =>
  request({
    method: 'post',
    url: '/recovery',
    data: { new_password, token },
  });

const changeExpiredPassword = (old_pwd, pwd, token) =>
  request({
    method: 'post',
    url: '/changepassword',
    data: { old_pwd, pwd, token },
  });

const getContractApi = (id_contract) =>
  request({
    method: 'post',
    url: '/affiliate/getcontract',
    data: { id_contract },
    responseType: 'blob',
  });

const acceptContractApi = (id_contract) =>
  request({
    method: 'post',
    url: '/affiliate/acceptcontract',
    data: { id_contract },
  });

const fieldExists = (email, cif, user, url) => {
  let formdata = new FormData();
  if (email) formdata.append('affiliate[email]', email);
  if (cif) formdata.append('affiliate[cif]', cif);
  if (user) formdata.append('affiliate[usuario]', user);
  if (url) formdata.append('affiliate[url]', url);

  return request({
    method: 'post',
    url: '/affiliate/exists',
    data: formdata,
  });
};

const set = ({ data, contact_info, key = '', recaptchaResponse }) => {
  return request({
    method: 'post',
    url: '/affiliate/set',
    data: { affiliate: data, key: key, contact_info, recaptcha_response: recaptchaResponse },
  });
};

const adminListNotValidated = (filters) => {
  let params = { filters };
  return request({
    method: 'get',
    url: '/admin/affiliate/getnotvalidatedlist',
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
};

const validate = (id) => {
  return request({
    method: 'post',
    url: '/admin/affiliate/validate',
    data: { id },
  });
};

const decline = (id) => {
  return request({
    method: 'delete',
    url: '/admin/affiliate/decline',
    data: { id },
  });
};

const generateKey = () => {
  return request({
    method: 'post',
    url: '/admin/affiliate/setkey',
  });
};

const UserService = {
  getProfile,
  updateProfile,
  generateApiKey,
  updatePassword,
  sendEmailPassword,
  recovery,
  changeExpiredPassword,
  getContractApi,
  acceptContractApi,
  fieldExists,
  set,
  adminListNotValidated,
  validate,
  decline,
  generateKey,
};

export default UserService;
