import { Card, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import translate from 'i18n/translate';

// style
import './index.scss';

const Legal = ({ gtm, ga, setGTM, setGA }) => {
  //const intl = useIntl();

  return (
    <Card className="mb-3">
      <Card.Header>{translate('herramientas-analitics')}</Card.Header>
      <Card.Body>
        <Row>
          <Col xl>
            <Form.Group as={Row} className="mb-3" controlId="formOwnnerName">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-google-tag-manager-explanation')}</Tooltip>}>
                <Form.Label column sm="6" className="font-weight-bold">
                  <span className="text">Google Tag Manager</span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="6">
                <Form.Control value={gtm} name="gtm" placeholder="GTM-XXXXXXX" onChange={(e) => setGTM(e.target.value)} />
              </Col>
            </Form.Group>
          </Col>
          <Col xl>
            <Form.Group as={Row} className="mb-3" controlId="formOwnerEmail">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{translate('herramientas-google-analitics-explanation')}</Tooltip>}>
                <Form.Label column sm="6" className="font-weight-bold">
                  <span className="text">Google Analitics</span>
                  <span className="help-icon float-right">
                    <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  </span>
                </Form.Label>
              </OverlayTrigger>
              <Col sm="6">
                <Form.Control value={ga} name="ga" placeholder="UA-XXXXX-X" onChange={(e) => setGA(e.target.value)} disabled />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Legal;
