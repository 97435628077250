import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Card, Col, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSave, faQuestionCircle, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { getLang } from 'libs/cookies';
import { metatagsBuild } from 'libs/whitelabels';
import { debounce } from 'libs/lodashAlt';

// Hooks
import useValidator from 'hooks/useValidator';

import MetatagSection from './metatagSection';
import DomainsList from './domainsList';
import Social from './social';
import Legal from './legal';
import Analitic from './analitic';
//import Favicons from './favicons';

import CustomButton from 'components/button';
import Loading from 'components/loading';
import Preview from 'components/whiteLabels/preview';

import WhiteLabelsService from 'shared/white-labels-service';

const WhiteBrandForm = () => {
  const { part } = useParams();

  const id_edit = part;

  const dispatch = useDispatch();

  //const [name, setName] = useState('');
  const [name, setName] = useState(undefined);
  // Social
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [telegram, setTelegram] = useState('');
  const [social_default_image, setSocialDefaultImage] = useState(undefined);
  const [afno, setAfno] = useState('');

  const [notifications_email, setNotificationsEmail] = useState('');
  const [mode, setMode] = useState('');
  const [ageVerification, setAgeVerification] = useState(0);
  const [logo, setLogo] = useState(undefined);
  const [edit_logo, setEditLogo] = useState(false);
  const [edit_favicon, setEditFavicon] = useState(false);
  const [edit_opengraph, setEditOpengraph] = useState(false);
  const [primary_color, setPrimaryColor] = useState('#fc531d');

  //const [domain_owner_name, setDomainOwnerName] = useState('');
  //const [domain_owner_email, setDomainOwnerEmail] = useState('');

  const [domain_owner_name, setDomainOwnerName] = useState(undefined);
  const [domain_owner_email, setDomainOwnerEmail] = useState(undefined);

  //const [use_live_thumbs, setUseLiveThumbs] = useState(true);       // TODO BORRAR

  const [domain, setDomain] = useState('');

  const [favicon, setFavicon] = useState(undefined);

  const [gtm, setGTM] = useState('');
  const [ga, setGA] = useState('');

  // MetaTags
  const initialStateMetatagsHome = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsGirls = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsBoys = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsTrans = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsCouples = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsOneFilter = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];
  const initialStateMetatagsRoom = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];
  const [metatagsHome, setMetatagsHome] = useState(initialStateMetatagsHome);
  const [metatagsGirls, setMetatagsGirls] = useState(initialStateMetatagsGirls);
  const [metatagsCouples, setMetatagsCouples] = useState(initialStateMetatagsCouples);
  const [metatagsBoys, setMetatagsBoys] = useState(initialStateMetatagsBoys);
  const [metatagsTrans, setMetatagsTrans] = useState(initialStateMetatagsTrans);
  const [metatagsOneFilter, setMetatagsOneFilter] = useState(initialStateMetatagsOneFilter);
  const [metatagsRoom, setMetatagsRoom] = useState(initialStateMetatagsRoom);

  let loading = useSelector((state) => state.loading.loading);
  const { formatMessage } = useIntl();

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  // Functions
  const saveTwitter = (e) => setTwitter(e.target.value);
  const saveInstagram = (e) => setInstagram(e.target.value);
  const saveTelegram = (e) => setTelegram(e.target.value);
  const saveSocialDefaultImage = (e) => setSocialDefaultImage(e.target.value);
  const savePrimaryColor = (e) => setPrimaryColor(e.target.value);
  const saveDomainEmail = (e) => setDomainOwnerEmail(e.target.value);
  const saveDomainName = (e) => setDomainOwnerName(e.target.value);

  const saveImage = (event, type) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].type.includes('image')) {
        if (!event.target.files[0].type.includes('webp')) {
          if (event.target.files[0].size <= 2097152) {
            // CHECK MAX SIZE
            switch (type) {
              case 'logo':
                setLogo(event.target.files[0]);
                setEditLogo(true);
                break;
              case 'favicon':
                // Check if is a PNG
                //if (!event.target.files[0].type.includes('svg')){
                // Check Favicon Size
                var img = document.createElement('img');
                img.onload = function () {
                  //debugger;
                  //console.log(this.width + " " + this.height);
                  if (this.width < 128 || this.height < 128) {
                    toast.error(<FormattedMessage id="global-image-size-too-small-128" />);
                    setFavicon(null);
                  }
                };
                var reader = new FileReader();
                reader.onloadend = function (ended) {
                  img.src = ended.target.result;
                };
                reader.readAsDataURL(event.target.files[0]);
                setFavicon(event.target.files[0]); // Always set favicon but after that checks the minimum size
                setEditFavicon(true);
                break;
              case 'opengraph':
                setSocialDefaultImage(event.target.files[0]);
                setEditOpengraph(true);
                break;
              default:
                setLogo(event.target.files[0]);
                break;
            }
          } else toast.error(<FormattedMessage id="global-file-too-big" />);
        } else toast.error(<FormattedMessage id="global-file-image-type-not-allowed" />);
      } else toast.error(<FormattedMessage id="global-file-must-be-image" />);
    }
  };

  const handleTabChange = (key, event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const debouncedOnChangeColor = debounce((name, value) => {
    savePrimaryColor({ target: { name, value } });
    //handleOnChange({target: { name, value }}, 'designForm');
    //console.log(value);
  }, 150);

  const updateBtn = () => {
    let config = {
      NAME: name,
      TWITTER: twitter,
      INSTAGRAM: instagram,
      TELEGRAM: telegram,
      AFNO: afno,
      NOTIFICATIONS_EMAIL: notifications_email,
      MODE: mode,
      AGE_VERIFICATION: Number(ageVerification),
      PRIMARY_COLOR: primary_color,
      DOMAIN_OWNER_NAME: domain_owner_name,
      DOMAIN_OWNER_EMAIL: domain_owner_email,
      GTM: gtm,
      GA: ga,
    };

    const metatags = metatagsBuild(
      metatagsHome,
      metatagsGirls,
      metatagsBoys,
      metatagsTrans,
      metatagsCouples,
      metatagsRoom,
      metatagsOneFilter
    );

    if (validator.allValid()) {
      config.ID = id_edit;
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.adminUpdate(config, [domain], metatags)
        .then((responseData) => {
          if (responseData.status === 'Failed') toast.error(<FormattedMessage id="promo-white-brand-failed-updating" />);
          else toast.success(<FormattedMessage id="promo-white-brand-updated" />);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  const history = useHistory();
  useEffect(() => {
    if (id_edit) {
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.adminGet(id_edit)
        .then((responseData) => {
          if (responseData.status === 'Accepted' && responseData.message.config.length > 0) {
            setName(responseData.message.config[0].NAME);
            responseData.message.config[0].TWITTER && setTwitter(responseData.message.config[0].TWITTER);
            responseData.message.config[0].INSTAGRAM && setInstagram(responseData.message.config[0].INSTAGRAM);
            responseData.message.config[0].TELEGRAM && setTelegram(responseData.message.config[0].TELEGRAM);
            responseData.message.config[0].SOCIAL_DEFAULT_IMAGE &&
              setSocialDefaultImage(responseData.message.config[0].SOCIAL_DEFAULT_IMAGE);
            setAfno(responseData.message.config[0].AFNO);
            setNotificationsEmail(responseData.message.config[0].NOTIFICATIONS_EMAIL);
            setMode(responseData.message.config[0].MODE);
            setAgeVerification(responseData.message.config[0].AGE_VERIFICATION ? responseData.message.config[0].AGE_VERIFICATION : 0);
            setLogo(responseData.message.config[0].LOGO);
            setPrimaryColor(responseData.message.config[0].PRIMARY_COLOR);
            setDomainOwnerName(responseData.message.config[0].DOMAIN_OWNER_NAME);
            setDomainOwnerEmail(responseData.message.config[0].DOMAIN_OWNER_EMAIL);
            setGTM(responseData.message.config[0].GTM);
            setGA(responseData.message.config[0].GA);
            //setUseLiveThumbs(responseData.message.config[0].USE_LIVE_THUMBS);
            //Favicon
            if (responseData.message.favicons[0]) {
              setFavicon(responseData.message.favicons[0].HREF);
            }

            // SET DOMAINS DATA
            //responseData.message.domains.map((item, index) => setDomains([item.URL]));
            setDomain(responseData.message.domains.find((item) => item.MAIN === 1).URL);

            // SET METATAGS DATA
            setMetatagsHome(responseData.message.metatags_build.home);
            setMetatagsGirls(responseData.message.metatags_build.segmentGirls);
            setMetatagsCouples(responseData.message.metatags_build.segmentCouples);
            setMetatagsBoys(responseData.message.metatags_build.segmentBoys);
            setMetatagsTrans(responseData.message.metatags_build.segmentTrans);
            setMetatagsOneFilter(responseData.message.metatags_build.onefilter);
            setMetatagsRoom(responseData.message.metatags_build.room);
          } else {
            //this.goToForm();
            toast.error('No se encontró la marca blanca');
            history.push('/admintools/marcas-blancas');
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else {
      history.push('/admintools/marcas-blancas');
    }
  }, [dispatch, id_edit]);

  if (loading) return <Loading />;

  const updateLogoBtn = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminUpdateLogo(id_edit, logo)
      .then((responseData) => {
        if (responseData.status === 'Failed') toast.error(<FormattedMessage id="promo-white-brand-file-updated-fail" />);
        else toast.success(<FormattedMessage id="promo-white-brand-file-updated" />);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const updateFaviconBtn = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminUpdateFavicon(id_edit, favicon)
      .then((responseData) => {
        if (responseData.status === 'Failed') toast.error(<FormattedMessage id="promo-white-brand-file-updated-fail" />);
        else toast.success(<FormattedMessage id="promo-white-brand-file-updated" />);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const updateOpengraphBtn = () => {
    dispatch({ type: 'SET_LOADING', loading: true });
    WhiteLabelsService.adminUpdateOpengraph(id_edit, social_default_image)
      .then((responseData) => {
        if (responseData.status === 'Failed') toast.error(<FormattedMessage id="promo-white-brand-file-updated-fail" />);
        else toast.success(<FormattedMessage id="promo-white-brand-file-updated" />);
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  return (
    <Row className="main-white-brand container-fluid">
      <Col sm="12" className="mb-2">
        <CustomButton onClick={(e) => history.push('/admintools/marcas-blancas')}>
          <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
          <FormattedMessage id="statistics-form-button-return" />
        </CustomButton>
      </Col>
      <Col sm="12">
        <Card className="mb-2">
          <Card.Header>
            <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> <FormattedMessage id="promo-setup-white-brand" /> {name}{' '}
          </Card.Header>
          <Card.Body>
            <Form id="form" className="form">
              <Row>
                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formName">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-name-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">
                          <FormattedMessage id="global-name" />
                        </span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control value={name} name="name" placeholder="" onChange={(e) => setName(e.target.value)} />
                      {validator.message(formatMessage({ id: 'global-name' }), name, 'max: 255|required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 required" controlId="formAfno">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-tracking-id-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">
                          <FormattedMessage id="global-campaign-id" />
                        </span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>

                    <Col sm="9">
                      <Form.Control value={afno} placeholder="" name="afno" onChange={(e) => setAfno(e.target.value)} />

                      {validator.message(formatMessage({ id: 'global-campaign-id' }), afno, 'max: 150|required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formNotificationsEmail">
                    <Form.Label column sm="3" className="font-weight-bold">
                      <FormattedMessage id="global-notification-email" />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        value={notifications_email}
                        name="notifications_email"
                        placeholder=""
                        onChange={(e) => setNotificationsEmail(e.target.value)}
                      />
                      {validator.message(
                        formatMessage({ id: 'global-notification-email' }),
                        notifications_email,
                        'max: 255|email|required'
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formMode">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-mode-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">Mode</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <select className="form-control" name="mode" onChange={(e) => setMode(e.target.value)} value={mode}>
                        <option value="REGISTERED">REGISTERED</option>
                        <option value="ANONYMOUS">ANONYMOUS</option>
                      </select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formAgeVerification">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-age-verification-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">Verificación de Edad</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <input type="checkbox" checked={ageVerification} onChange={(e) => setAgeVerification(e.target.checked)} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formLogo">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-logo-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">Logo</span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control
                        accept="image/*"
                        className="form-control"
                        type="file"
                        name="logo"
                        onChange={(e) => saveImage(e, 'logo')}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formLogoBtn">
                    <Col sm="3">
                      {id_edit && logo && edit_logo && <CustomButton onClick={(e) => updateLogoBtn()}>Actualizar Logo</CustomButton>}
                    </Col>
                    <Col sm="9">
                      {id_edit && logo && <img style={{ maxWidth: '128px' }} src={logo} className={'img-preview'} alt="" />}
                      {id_edit && logo && edit_logo && (
                        <img style={{ maxWidth: '128px' }} src={URL.createObjectURL(logo)} className={'img-preview'} alt="" />
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formFavicon">
                    <Form.Label column sm="3" className="font-weight-bold">
                      Favicon
                    </Form.Label>

                    <Col sm="9">
                      <Form.Control
                        accept="image/*"
                        className="form-control"
                        type="file"
                        name="favicon"
                        onChange={(e) => saveImage(e, 'favicon')}
                      />
                      {validator.message('favicon', favicon, 'required')}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formLogoBtn">
                    <Col sm="3">
                      {id_edit && favicon && edit_favicon && (
                        <CustomButton onClick={(e) => updateFaviconBtn()}>Actualizar Favicon</CustomButton>
                      )}
                    </Col>
                    <Col sm="9">
                      {id_edit && favicon && !edit_favicon && (
                        <img style={{ maxWidth: '128px' }} src={favicon} className={'img-preview'} alt="" />
                      )}
                      {id_edit && favicon && edit_favicon && (
                        <img style={{ maxWidth: '128px' }} src={URL.createObjectURL(favicon)} className={'img-preview'} alt="" />
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formPrimaryColor">
                    <Form.Label column sm="3" className="font-weight-bold">
                      <FormattedMessage id="global-primary-color" />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="color"
                        name="primary_color"
                        value={primary_color}
                        title="Seleccione el color"
                        onChange={(e) => debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
                      />
                    </Col>
                  </Form.Group>

                  <Social
                    twitter={twitter}
                    saveTwitter={saveTwitter}
                    instagram={instagram}
                    saveInstagram={saveInstagram}
                    telegram={telegram}
                    saveTelegram={saveTelegram}
                    social_default_image={social_default_image}
                    saveSocialDefaultImage={saveSocialDefaultImage}
                    saveImage={saveImage}
                    validator={validator}
                    edit={true}
                    edit_opengraph={edit_opengraph}
                    updateOpengraphBtn={updateOpengraphBtn}
                  />
                  <Analitic gtm={gtm} ga={ga} setGTM={setGTM} setGA={setGA} />
                </Col>
                <Col xl="6">
                  <DomainsList domain={domain} setDomain={setDomain} edit={true} validator={validator} />
                  <Legal
                    domain_owner_name={domain_owner_name}
                    saveDomainName={saveDomainName}
                    domain_owner_email={domain_owner_email}
                    saveDomainEmail={saveDomainEmail}
                    validator={validator}
                  />
                </Col>
              </Row>
              <MetatagSection
                handleTabChange={handleTabChange}
                metatagsHome={metatagsHome}
                setMetatagsHome={setMetatagsHome}
                metatagsGirls={metatagsGirls}
                setMetatagsGirls={setMetatagsGirls}
                metatagsTrans={metatagsTrans}
                setMetatagsTrans={setMetatagsTrans}
                metatagsBoys={metatagsBoys}
                setMetatagsBoys={setMetatagsBoys}
                metatagsCouples={metatagsCouples}
                setMetatagsCouples={setMetatagsCouples}
                metatagsOneFilter={metatagsOneFilter}
                setMetatagsOneFilter={setMetatagsOneFilter}
                metatagsRoom={metatagsRoom}
                setMetatagsRoom={setMetatagsRoom}
              />
              <Preview logo={logo} color={primary_color} id_edit={id_edit} />
              <Row>
                <Col>
                  {
                    <CustomButton rounded className="w-100" onClick={updateBtn}>
                      <FontAwesomeIcon icon={faSave} className="mr-2" />
                      {id_edit ? (
                        <span>
                          <FormattedMessage id="global-update" />
                        </span>
                      ) : (
                        <span>
                          <FormattedMessage id="administration-data-button" />
                        </span>
                      )}
                    </CustomButton>
                  }
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WhiteBrandForm;
