export const createMetatags = (metatagsItem, dimension) => {
  let result = {};
  metatagsItem.forEach((item, index) => {
    let index_title = dimension + '-title-' + item.lang.toLowerCase();
    let index_description = dimension + '-description-' + item.lang.toLowerCase();
    result[index_title] = item.values.title;
    result[index_description] = item.values.description;
  });

  return result;
};

export const metatagsBuild = (
  metatagsHome,
  metatagsGirls,
  metatagsBoys,
  metatagsTrans,
  metatagsCouples,
  metatagsRoom,
  metatagsOneFilter
) => {
  let result = {};

  let home = createMetatags(metatagsHome, 'home');
  let girls = createMetatags(metatagsGirls, 'segmentGirls');
  let boys = createMetatags(metatagsBoys, 'segmentBoys');
  let trans = createMetatags(metatagsTrans, 'segmentTrans');
  let couples = createMetatags(metatagsCouples, 'segmentCouples');
  let room = createMetatags(metatagsRoom, 'room');
  let onefilter = createMetatags(metatagsOneFilter, 'onefilter');

  Object.keys(home).map((key, value) => (result[key] = home[key]));
  Object.keys(girls).map((key, value) => (result[key] = girls[key]));
  Object.keys(boys).map((key, value) => (result[key] = boys[key]));
  Object.keys(trans).map((key, value) => (result[key] = trans[key]));
  Object.keys(couples).map((key, value) => (result[key] = couples[key]));
  Object.keys(room).map((key, value) => (result[key] = room[key]));
  Object.keys(onefilter).map((key, value) => (result[key] = onefilter[key]));

  return result;
};
