const Boys = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M15 7L15 5 21 5 21 11 19 11 19 8.41 15.17 12.24 13.75 10.83 17.58 7z"></path>
        <path d="M12 9c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 2c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"></path>
      </g>
    </svg>
  );
};

export default Boys;
