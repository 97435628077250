import { NavLink } from 'react-router-dom';
import { Accordion, Button, Card, Col, Row, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFilter, faVideo } from '@fortawesome/free-solid-svg-icons';

import GirlsIcon from 'components/icons/olecams/girls';
import BoysIcon from 'components/icons/olecams/boys';
import TransIcon from 'components/icons/olecams/trans';
import CouplesIcon from 'components/icons/olecams/couples';
import SupportIcon from 'components/icons/sopport';

import translate from 'i18n/translate';

import MetatagItem from './metatagItem';

const MetatagSection = ({
  handleTabChange,
  metatagsHome,
  setMetatagsHome,
  metatagsGirls,
  setMetatagsGirls,
  metatagsTrans,
  setMetatagsTrans,
  metatagsBoys,
  setMetatagsBoys,
  metatagsCouples,
  setMetatagsCouples,
  metatagsOneFilter,
  setMetatagsOneFilter,
  metatagsRoom,
  setMetatagsRoom,
}) => {
  return (
    <Row className="mb-3">
      <Col>
        <Accordion className="metatags-accordion">
          <Card className="mb-2">
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Metatags
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Row className="mb-2">
                  <Col sm="12" className="text-center">
                    <NavLink target="_blank" to="/soporte" role="button" className="underlined">
                      {translate('herramientas-white-label-problem-creating-support')} <SupportIcon color="#fc531d" />
                    </NavLink>
                  </Col>
                </Row>
                <Tab.Container id="left-tabs-example" defaultActiveKey="Home">
                  <Row>
                    <Col sm={3}>
                      <Nav variant="pills" activeKey="Home" className="flex-column" onSelect={handleTabChange}>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="Home">
                            Home <FontAwesomeIcon icon={faHome} className="ml-2" />
                          </Nav.Link>{' '}
                        </Nav.Item>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="segmentGirls">
                            {translate('global-girls')} <GirlsIcon color="#fc531d" />
                          </Nav.Link>{' '}
                        </Nav.Item>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="segmentTrans">
                            {translate('global-trans')} <TransIcon color="#fc531d" />{' '}
                          </Nav.Link>{' '}
                        </Nav.Item>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="segmentBoys">
                            {translate('global-boys')} <BoysIcon color="#fc531d" />{' '}
                          </Nav.Link>{' '}
                        </Nav.Item>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="segmentCouples">
                            {translate('global-couples')} <CouplesIcon color="#fc531d" />
                          </Nav.Link>{' '}
                        </Nav.Item>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="onefilter">
                            {translate('global-filters')} <FontAwesomeIcon icon={faFilter} className="ml-2" />
                          </Nav.Link>{' '}
                        </Nav.Item>
                        <Nav.Item>
                          {' '}
                          <Nav.Link eventKey="room">
                            {translate('global-room')} <FontAwesomeIcon icon={faVideo} className="ml-2" />
                          </Nav.Link>{' '}
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="Home">
                          <MetatagItem
                            title="Home"
                            icon={faHome}
                            items={metatagsHome}
                            setItems={setMetatagsHome}
                            metaKeys={['{site-title}']}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="segmentGirls">
                          <MetatagItem
                            title={translate('global-girls')}
                            icon={faHome}
                            items={metatagsGirls}
                            setItems={setMetatagsGirls}
                            metaKeys={['{site-title}', '{segment}']}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="segmentTrans">
                          <MetatagItem
                            title={translate('global-trans')}
                            icon={faHome}
                            items={metatagsTrans}
                            setItems={setMetatagsTrans}
                            metaKeys={['{site-title}', '{segment}']}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="segmentBoys">
                          <MetatagItem
                            title={translate('global-boys')}
                            icon={faHome}
                            items={metatagsBoys}
                            setItems={setMetatagsBoys}
                            metaKeys={['{site-title}', '{segment}']}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="segmentCouples">
                          <MetatagItem
                            title={translate('global-couples')}
                            icon={faHome}
                            items={metatagsCouples}
                            setItems={setMetatagsCouples}
                            metaKeys={['{site-title}', '{segment}']}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="onefilter">
                          <MetatagItem
                            title={translate('global-filters')}
                            icon={faHome}
                            items={metatagsOneFilter}
                            setItems={setMetatagsOneFilter}
                            metaKeys={['{site-title}', '{filter1}', '{segment}']}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="room">
                          <MetatagItem
                            title={translate('global-room')}
                            icon={faFilter}
                            items={metatagsRoom}
                            setItems={setMetatagsRoom}
                            metaKeys={['{site-title}', '{nick}']}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
};

export default MetatagSection;
