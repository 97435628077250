import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Card, Col, Form, Row, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSave, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Loading from 'components/loading';

import { getLang } from 'libs/cookies';
import { debounce } from 'libs/lodashAlt';
import { metatagsBuild } from 'libs/whitelabels';

// Hooks
import useValidator from 'hooks/useValidator';

import MetatagSection from './metatagSection';
import DomainsList from './domainsList';
import Social from './social';
import Legal from './legal';
import Analitic from './analitic';
import Preview from 'components/whiteLabels/preview';
import CustomButton from 'components/button';
//import Favicons from './favicons';

import WhiteLabelsService from 'shared/white-labels-service';

// style
import './index.scss';

const WhiteBrandForm = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.auth);
  const prefix_afno = '1-' + auth.id_affiliate + '-';

  const id_edit = useSelector((state) => state.whitebrand.id_edit_whitebrand);
  //const [name, setName] = useState('');
  const [name, setName] = useState(undefined);
  // Social
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [telegram, setTelegram] = useState('');
  const [social_default_image, setSocialDefaultImage] = useState(undefined);
  const [afno, setAfno] = useState('');

  const [notifications_email, setNotificationsEmail] = useState('');
  const [logo, setLogo] = useState(undefined);
  const [primary_color, setPrimaryColor] = useState('#fc531d');

  //const [domain_owner_name, setDomainOwnerName] = useState('');
  //const [domain_owner_email, setDomainOwnerEmail] = useState('');

  const [domain_owner_name, setDomainOwnerName] = useState(undefined);
  const [domain_owner_email, setDomainOwnerEmail] = useState(undefined);

  //const [use_live_thumbs, setUseLiveThumbs] = useState(true);       // TODO BORRAR

  const [domain, setDomain] = useState('');
  //const [domains, setDomains] = useState([]);

  const [favicon, setFavicon] = useState(undefined);

  const [gtm, setGTM] = useState('');
  const [ga, setGA] = useState('');

  // MetaTags
  const initialStateMetatagsHome = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsGirls = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsBoys = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsTrans = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsCouples = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];

  const initialStateMetatagsOneFilter = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];
  const initialStateMetatagsRoom = [
    { lang: 'es', values: { title: '', description: '' } },
    { lang: 'en', values: { title: '', description: '' } },
    { lang: 'de', values: { title: '', description: '' } },
    { lang: 'it', values: { title: '', description: '' } },
    { lang: 'fr', values: { title: '', description: '' } },
    { lang: 'pt', values: { title: '', description: '' } },
  ];
  const [metatagsHome, setMetatagsHome] = useState(initialStateMetatagsHome);
  const [metatagsGirls, setMetatagsGirls] = useState(initialStateMetatagsGirls);
  const [metatagsCouples, setMetatagsCouples] = useState(initialStateMetatagsCouples);
  const [metatagsBoys, setMetatagsBoys] = useState(initialStateMetatagsBoys);
  const [metatagsTrans, setMetatagsTrans] = useState(initialStateMetatagsTrans);
  const [metatagsOneFilter, setMetatagsOneFilter] = useState(initialStateMetatagsOneFilter);
  const [metatagsRoom, setMetatagsRoom] = useState(initialStateMetatagsRoom);

  let loading = useSelector((state) => state.loading.loading);
  const intl = useIntl();

  const [validator, showValidationMessage] = useValidator({}, {}, getLang());

  // Functions
  const saveTwitter = (e) => setTwitter(e.target.value);
  const saveInstagram = (e) => setInstagram(e.target.value);
  const saveTelegram = (e) => setTelegram(e.target.value);
  const saveSocialDefaultImage = (e) => setSocialDefaultImage(e.target.value);
  const savePrimaryColor = (e) => setPrimaryColor(e.target.value);
  const saveDomainEmail = (e) => setDomainOwnerEmail(e.target.value);
  const saveDomainName = (e) => setDomainOwnerName(e.target.value);

  const saveImage = (event, type) => {
    // Check Size also
    if (event.target.files && event.target.files[0]) {
      if (!event.target.files[0].type.includes('webp')) {
        if (event.target.files[0].size > 2048) {
          // CHECK MAX SIZE
          switch (type) {
            case 'logo':
              setLogo(event.target.files[0]);
              break;
            case 'favicon':
              setFavicon(event.target.files[0]);
              break;
            case 'opengraph':
              setSocialDefaultImage(event.target.files[0]);
              break;
            default:
              setLogo(event.target.files[0]);
              break;
          }
        } else toast.error(<FormattedMessage id="global-file-too-big" />);
      } else toast.error(<FormattedMessage id="global-file-image-type-not-allowed" />);
    }
  };

  const handleTabChange = (key, event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const debouncedOnChangeColor = debounce((name, value) => {
    savePrimaryColor({ target: { name, value } });
    //handleOnChange({target: { name, value }}, 'designForm');
    //console.log(value);
  }, 150);

  const updateBtn = () => {
    let config = {
      NAME: name,
      TWITTER: twitter,
      INSTAGRAM: instagram,
      TELEGRAM: telegram,
      AFNO: prefix_afno + afno,
      NOTIFICATIONS_EMAIL: notifications_email,
      PRIMARY_COLOR: primary_color,
      DOMAIN_OWNER_NAME: domain_owner_name,
      DOMAIN_OWNER_EMAIL: domain_owner_email,
      GTM: gtm,
      GA: ga,
    };

    const metatags = metatagsBuild(
      metatagsHome,
      metatagsGirls,
      metatagsBoys,
      metatagsTrans,
      metatagsCouples,
      metatagsRoom,
      metatagsOneFilter
    );

    if (validator.allValid()) {
      config.ID = id_edit;
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.update(config, metatags)
        .then((responseData) => {
          if (responseData.status === 'Failed') {
            toast.error(<FormattedMessage id="promo-white-brand-failed-updating" />);
          } else toast.success(<FormattedMessage id="promo-white-brand-updated" />);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else showValidationMessage(true);
  };

  const history = useHistory();
  useEffect(() => {
    if (id_edit) {
      dispatch({ type: 'SET_LOADING', loading: true });
      WhiteLabelsService.get({ id: id_edit })
        .then((responseData) => {
          if (responseData.status === 'Accepted' && responseData.message.config.length > 0) {
            let comp_afno = responseData.message.config[0].AFNO;
            let pref_afno = ('1-' + auth.id_affiliate + '-').toString();
            let tracking_id = comp_afno.substring(pref_afno.length);

            setName(responseData.message.config[0].NAME);
            responseData.message.config[0].TWITTER && setTwitter(responseData.message.config[0].TWITTER);
            responseData.message.config[0].INSTAGRAM && setInstagram(responseData.message.config[0].INSTAGRAM);
            responseData.message.config[0].TELEGRAM && setTelegram(responseData.message.config[0].TELEGRAM);
            responseData.message.config[0].SOCIAL_DEFAULT_IMAGE &&
              setSocialDefaultImage(responseData.message.config[0].SOCIAL_DEFAULT_IMAGE);
            setAfno(tracking_id);
            setNotificationsEmail(responseData.message.config[0].NOTIFICATIONS_EMAIL);
            setLogo(responseData.message.config[0].LOGO);
            setPrimaryColor(responseData.message.config[0].PRIMARY_COLOR);
            setDomainOwnerName(responseData.message.config[0].DOMAIN_OWNER_NAME);
            setDomainOwnerEmail(responseData.message.config[0].DOMAIN_OWNER_EMAIL);
            setGTM(responseData.message.config[0].GTM);
            setGA(responseData.message.config[0].GA);
            //setUseLiveThumbs(responseData.message.config[0].USE_LIVE_THUMBS);
            //Favicon
            if (responseData.message.favicons[0]) {
              setFavicon(responseData.message.favicons[0].HREF);
            }

            // SET DOMAINS DATA
            //responseData.message.domains.map((item, index) => setDomains([...domains, item.URL]));
            setDomain(responseData.message.domains[0].URL);

            // SET METATAGS DATA
            setMetatagsHome(responseData.message.metatags_build.home);
            setMetatagsGirls(responseData.message.metatags_build.segmentGirls);
            setMetatagsCouples(responseData.message.metatags_build.segmentCouples);
            setMetatagsBoys(responseData.message.metatags_build.segmentBoys);
            setMetatagsTrans(responseData.message.metatags_build.segmentTrans);
            setMetatagsOneFilter(responseData.message.metatags_build.onefilter);
            setMetatagsRoom(responseData.message.metatags_build.room);
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    } else {
      history.push('/promocion/marca-blanca');
    }
  }, [dispatch, id_edit]);

  if (loading) return <Loading />;

  return (
    <Row className="main-white-brand container-fluid">
      <Col sm="12">
        <Card className="mb-2">
          <Card.Header>
            <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> <FormattedMessage id="promo-setup-white-brand" /> {name}{' '}
          </Card.Header>
          <Card.Body>
            <Form id="form" className="form">
              <Row>
                <Col xl="6">
                  <Form.Group as={Row} className="mb-3 required" controlId="formAfno">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-tracking-id-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">
                          <FormattedMessage id="global-campaign-id" />
                        </span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>

                    <Col sm="9">
                      <InputGroup className="">
                        <InputGroup.Text>{prefix_afno}</InputGroup.Text>
                        <Form.Control className="w-auto f-left" value={afno} placeholder="" name="afno" disabled />
                      </InputGroup>

                      {validator.message(intl.formatMessage({ id: 'global-campaign-id' }), afno, 'max: 150|required')}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="formNotificationsEmail">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-email-notification-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">
                          <FormattedMessage id="global-notification-email" />
                        </span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control
                        value={notifications_email}
                        name="notifications_email"
                        placeholder=""
                        onChange={(e) => setNotificationsEmail(e.target.value)}
                      />
                      {validator.message(
                        intl.formatMessage({ id: 'global-notification-email' }),
                        notifications_email,
                        'max: 255|email|required'
                      )}
                    </Col>
                  </Form.Group>

                  {id_edit && logo && (
                    <Form.Group as={Row} className="mb-3" controlId="formLogo">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            <FormattedMessage id="herramientas-white-label-logo-explanation" />
                          </Tooltip>
                        }>
                        <Form.Label column sm="3" className="font-weight-bold">
                          <span className="text">Logo</span>
                          <span className="help-icon float-right">
                            <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                          </span>
                        </Form.Label>
                      </OverlayTrigger>
                      <Col sm="9">
                        <img style={{ maxWidth: '128px' }} src={logo} className={'img-preview'} alt="" />
                      </Col>
                    </Form.Group>
                  )}

                  {id_edit && favicon && (
                    <Form.Group as={Row} className="mb-3" controlId="formFavicon">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            <FormattedMessage id="herramientas-white-label-favicon-explanation" />
                          </Tooltip>
                        }>
                        <Form.Label column sm="3" className="font-weight-bold">
                          <span className="text">Favicon</span>
                          <span className="help-icon float-right">
                            <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                          </span>
                        </Form.Label>
                      </OverlayTrigger>
                      <Col sm="9">
                        <img style={{ maxWidth: '128px' }} src={favicon} className={'img-preview'} alt="" />
                      </Col>
                    </Form.Group>
                  )}

                  <Form.Group as={Row} className="mb-3" controlId="formPrimaryColor">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <FormattedMessage id="herramientas-white-label-primary-color-explanation" />
                        </Tooltip>
                      }>
                      <Form.Label column sm="3" className="font-weight-bold">
                        <span className="text">
                          <FormattedMessage id="global-primary-color" />
                        </span>
                        <span className="help-icon float-right">
                          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                        </span>
                      </Form.Label>
                    </OverlayTrigger>
                    <Col sm="9">
                      <Form.Control
                        type="color"
                        name="primary_color"
                        value={primary_color}
                        title="Seleccione el color"
                        onChange={(e) => debouncedOnChangeColor(e.nativeEvent.target.name, e.nativeEvent.target.value)}
                      />
                    </Col>
                  </Form.Group>

                  <Social
                    twitter={twitter}
                    saveTwitter={saveTwitter}
                    instagram={instagram}
                    saveInstagram={saveInstagram}
                    telegram={telegram}
                    saveTelegram={saveTelegram}
                    social_default_image={social_default_image}
                    saveSocialDefaultImage={saveSocialDefaultImage}
                    saveImage={saveImage}
                    validator={validator}
                    edit={true}
                  />
                  <Analitic gtm={gtm} ga={ga} setGTM={setGTM} setGA={setGA} />
                </Col>
                <Col xl="6">
                  <DomainsList domain={domain} setDomain={setDomain} edit={true} validator={validator} />
                  <Legal
                    domain_owner_name={domain_owner_name}
                    saveDomainName={saveDomainName}
                    domain_owner_email={domain_owner_email}
                    saveDomainEmail={saveDomainEmail}
                    validator={validator}
                  />
                </Col>
              </Row>
              <MetatagSection
                handleTabChange={handleTabChange}
                metatagsHome={metatagsHome}
                setMetatagsHome={setMetatagsHome}
                metatagsGirls={metatagsGirls}
                setMetatagsGirls={setMetatagsGirls}
                metatagsTrans={metatagsTrans}
                setMetatagsTrans={setMetatagsTrans}
                metatagsBoys={metatagsBoys}
                setMetatagsBoys={setMetatagsBoys}
                metatagsCouples={metatagsCouples}
                setMetatagsCouples={setMetatagsCouples}
                metatagsOneFilter={metatagsOneFilter}
                setMetatagsOneFilter={setMetatagsOneFilter}
                metatagsRoom={metatagsRoom}
                setMetatagsRoom={setMetatagsRoom}
              />
              <Preview logo={logo} color={primary_color} id_edit={id_edit} />
              <Row>
                <Col>
                  <CustomButton rounded className="w-100" onClick={updateBtn}>
                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                    {id_edit ? (
                      <span>
                        <FormattedMessage id="global-update" />
                      </span>
                    ) : (
                      <span>
                        <FormattedMessage id="administration-data-button" />
                      </span>
                    )}
                  </CustomButton>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WhiteBrandForm;
