import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'libs/lodashAlt';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Container, Col, Row } from 'react-bootstrap';

//translate
import translate from 'i18n/translate';

import UserService from 'shared/user-service';

//Component
import CustomCard from 'components/card';
import Search from 'components/icons/search';
import Button from 'components/button';
import Loading from 'components/loading';
import Pagination from 'components/pagination';
import IconHerramienta from 'components/icons/herramienta';

import GenerateKey from './generateKey';

const Activation = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    search: '',
    pager: { page: '1', items: '10' },
  });

  const [actAfiliados, setActAfiliados] = useState(null);

  const getList = useCallback(
    (filters) => {
      dispatch({ type: 'SET_LOADING', loading: true });
      UserService.adminListNotValidated(filters)
        .then((responseData) => {
          if (responseData.status === 'Accepted') setActAfiliados(responseData.message);
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    },
    [dispatch]
  );

  useEffect(() => {
    getList(filters);
  }, [getList, filters]);

  const searchBtn = (e) => {
    e.preventDefault();

    setFilters({
      ...filters,
      search: search,
      pager: {
        ...filters.pager,
        page: 1,
      },
    });
  };

  const validate = (id) => {
    if (window.confirm(intl.formatMessage({ id: 'herramientas-confirm-alert' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      UserService.validate(id)
        .then((responseData) => {
          if (responseData.status !== 'Failed') getList(filters);
          else toast.error(translate('herramientas-affiliate-error-request'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const decline = (id) => {
    if (window.confirm(intl.formatMessage({ id: 'herramientas-confirm-alert' }))) {
      dispatch({ type: 'SET_LOADING', loading: true });
      UserService.decline(id)
        .then((responseData) => {
          if (responseData.status !== 'Failed') getList(filters);
          else toast.error(translate('herramientas-affiliate-error-request'));
        })
        .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    }
  };

  const paginate = (e) => {
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        page: e.target.value,
      },
    });
  };

  const handleChangeNumberOfColum = (e) => {
    setFilters({
      ...filters,
      pager: {
        ...filters.pager,
        items: e.target.value,
        page: 1,
      },
    });
  };

  const nextPage = (page) => {
    page++;
    if (page <= actAfiliados.pager.pages) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      setFilters({
        ...filters,
        pager: {
          ...filters.pager,
          page: page,
        },
      });
    }
  };
  const _class = 'validate_aff';

  if (loading) return <Loading />;

  return (
    <Container className="mw-100 ha-discounts">
      <Row>
        <Col sm="12">
          <GenerateKey />
        </Col>
      </Row>
      {!isEmpty(actAfiliados) && (
        <Row>
          <Col sm="12">
            <div className={`herramientas-administrativas-content ${_class}`}>
              <CustomCard icon={<IconHerramienta color="#4b5056" />} title={translate('herramientas-affiliate-active-name')}>
                <div className="banner-header-content">
                  <form className={`search-wrapper ${props.class}`} onSubmit={searchBtn}>
                    <div className="form-group input-content">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <Search search={searchBtn} />
                    </div>
                  </form>
                </div>
              </CustomCard>
              {actAfiliados.affiliates.length > 0 ? (
                <>
                  <div className="banner-table-content">
                    <Table data={actAfiliados.affiliates} validate={validate} decline={decline} />
                  </div>
                  <Pagination
                    handleChangeNumberOfColum={handleChangeNumberOfColum}
                    pager={actAfiliados.pager}
                    paginate={paginate}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    total={actAfiliados.total}
                  />
                </>
              ) : (
                <>No existen resultados</>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Activation;

const Table = ({ data, validate, decline }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">{translate('global-id')}</th>
          <th scope="col">{translate('global-name')}</th>
          <th scope="col">{translate('herramientas-banner-table-email')}</th>
          <th scope="col">{translate('global-url')}</th>
          <th scope="col">{translate('global-type')}</th>
          <th scope="col">{translate('global-description')}</th>
          <th scope="col">{translate('herramientas-banner-table-country')}</th>
          <th scope="col">{translate('global-date-register')}</th>
          <th scope="col">{translate('herramientas-affiliate-button-active')}</th>
          <th scope="col">{translate('global-decline')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index} className={(index + 1) % 2 ? 'impar' : 'par'}>
              <td>{item.id}</td>
              <td>{item.usuario}</td>
              <td>{item.email}</td>
              <td>
                <Link to={{ pathname: item.url }} target="_blank" rel="noopener noreferrer">
                  {item.url}
                </Link>
              </td>
              <td>{item.traffic_source_type}</td>
              <td>{item.descripcion}</td>
              <td>{item.pais}</td>
              <td>{item.fecha.split('-').reverse().join('-')}</td>
              <td>
                <div className="button-content">
                  <Button onClick={(e) => validate(item.id)}>{translate('herramientas-affiliate-button-active')}</Button>
                </div>
              </td>
              <td>
                <div className="button-content">
                  <Button red onClick={(e) => decline(item.id)}>
                    {translate('global-decline')}
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
