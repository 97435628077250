import { Container, Nav, Navbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { PUBLIC } from 'shared/panel-links';

const Menu = () => {
  return (
    <>
      <header className="header">
        <Container>
          <Navbar className="menu navbar-custom" expand="lg">
            <div className="logo-wrapper">
              <NavLink exact activeClassName="active" to="/" role="button">
                <img src="/images/logo.svg" alt="logo" />
              </NavLink>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <NavLink exact activeClassName="active" to="/" role="button" className="nav-link">
                  <FormattedMessage id="public-menu-home" />
                </NavLink>
                <NavLink exact activeClassName="active" to={PUBLIC.RATES} role="button" className="nav-link">
                  <FormattedMessage id="public-menu-taxes" />
                </NavLink>
                <NavLink exact activeClassName="active" to={PUBLIC.PRODUCTS} role="button" className="nav-link">
                  <FormattedMessage id="public-menu-products" />
                </NavLink>
                <NavLink exact activeClassName="active" to={PUBLIC.WHITE_BRAND} role="button" className="nav-link">
                  <FormattedMessage id="public-menu-white-brand" />
                </NavLink>
                <NavLink exact activeClassName="active" to={PUBLIC.CONTACT} role="button" className="nav-link">
                  <FormattedMessage id="public-menu-contact" />
                </NavLink>
                <NavLink className="btn btn-transparent nav-link-btn" to={PUBLIC.LOGIN} role="button">
                  <FormattedMessage id="public-menu-login" />
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Menu;
