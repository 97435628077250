const Girls = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="currentColor" fillRule="evenodd">
        <path d="M13 12.9L13 16 15 16 15 18 13 18 13 20 11 20 11 18 9 18 9 16 11 16 11 12.9z"></path>
        <path d="M12 4c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm0 2c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3z"></path>
      </g>
    </svg>
  );
};

export default Girls;
