import { Card, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const MetatagItem = ({ title, icon, items, setItems, metaKeys }) => {
  const base_src = process.env.PUBLIC_URL;

  const baseTitleControl = 'formHomeTitle';
  const baseDescControl = 'formHomeDescription';

  const saveValue = (e, which, lang, value) =>
    setItems((currentItems) => {
      currentItems.find((item) => item.lang === lang).values[which] = e.target.value;
      return currentItems;
    });

  return (
    <Card className="mb-3">
      <Card.Header> {title}</Card.Header>
      <Card.Body>
        <Row className="text-center">
          <Col md="12">
            <FormattedMessage id="herramientas-white-label-metatag-keywords" />
          </Col>
        </Row>
        <Row className="text-center">
          <Col md="12">
            {metaKeys.map((element, index, arr) => {
              return <div key={index}>{element}</div>;
            })}
          </Col>
        </Row>
        <hr />
        <br />
        {items.map((element, index, arr) => {
          let src = base_src + '/flags/' + element.lang + '.png';
          let separator = true;

          separator = arr.length - 1 !== index ? true : false;
          return (
            <div key={index}>
              <Row>
                <Col md="12">
                  <Form.Group as={Row} className="mb-3" controlId={baseTitleControl + element.lang}>
                    <Form.Label column sm="3" className="font-weight-bold">
                      <FormattedMessage id="global-title" /> <img className="ml-1 mb-1" src={src} alt={element.lang} />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        //value={element.values.title}
                        defaultValue={element.values.title}
                        placeholder=""
                        style={{ height: '60px' }}
                        onChange={(e) => saveValue(e, 'title', element.lang, e.target.value)}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId={baseDescControl + element.lang}>
                    <Form.Label column sm="3" className="font-weight-bold">
                      <FormattedMessage id="global-description" /> <img className="ml-1 mb-1" src={src} alt={element.lang} />
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        //value{element.values.description}
                        defaultValue={element.values.description}
                        placeholder=""
                        style={{ height: '60px' }}
                        onChange={(e) => saveValue(e, 'description', element.lang, e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {separator && <hr />}
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default MetatagItem;
